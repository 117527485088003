import { isEqual } from 'underscore';

// Action types
export const ADD_OUTCOMES = 'ADD_OUTCOMES';
export const UPDATE_OUTCOME = 'UPDATE_OUTCOME';
export const UPSERT_OUTCOMES = 'UPSERT_OUTCOMES';
export const REMOVE_OUTCOMES = 'REMOVE_OUTCOMES';

const initialState = {};

// Action creators
export const addOutcomes = (outcomes) => ({
  type: ADD_OUTCOMES,
  outcomes,
});

export const updateOutcome = (outcomeId, attributes) => ({
  type: UPDATE_OUTCOME,
  outcomeId,
  attributes,
});

export const upsertOutcomes = (outcomes) => ({
  type: UPSERT_OUTCOMES,
  outcomes,
});

export const removeOutcomes = (outcomeIds) => ({
  type: REMOVE_OUTCOMES,
  outcomeIds,
});

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_OUTCOMES:
    case UPSERT_OUTCOMES:
      if (!action.outcomes) return state;

      return {
        ...state,
        ...action.outcomes.reduce((acc, outcome) => {
          const currentOutcome = state[outcome.id];
          const currentOutcomeRefCount = (currentOutcome && currentOutcome.refCount) || 0;
          const isUpdateOlder = (currentOutcome && currentOutcome.timestamp > outcome.timestamp);

          let newOutcome = null;
          if (action.type === UPSERT_OUTCOMES) {
            // Sometimes the upsert we try to apply are outdated
            if (isUpdateOlder) {
              newOutcome = {
                ...outcome,
                ...currentOutcome,
                refCount: Math.max(currentOutcomeRefCount, 1),
              };
            } else {
              newOutcome = {
                ...currentOutcome,
                ...outcome,
                refCount: Math.max(currentOutcomeRefCount, 1),
              };
            }
          } else if (isUpdateOlder) {
            newOutcome = {
              ...outcome,
              ...currentOutcome,
              refCount: currentOutcomeRefCount + 1,
            };
          } else {
            newOutcome = {
              ...currentOutcome,
              ...outcome,
              refCount: currentOutcomeRefCount + 1,
            };
          }

          acc[outcome.id] = currentOutcome
            && isEqual(currentOutcome, newOutcome) ? currentOutcome : newOutcome;

          return acc;
        }, {}),
      };

    case UPDATE_OUTCOME:
      const { outcomeId } = action;
      const { attributes } = action;
      const outcome = state[outcomeId];

      if (!outcome) {
        return state;
      }

      // if the new attributes' timestamp is older than the current one,
      // use the new fields as base and update them with current data
      // other wise use new data to overwrite old values
      if ((outcome.timestamp || 0) > action.attributes.timestamp) {
        return {
          ...state,
          [outcomeId]: { ...attributes, ...outcome },
        };
      }
      return {
        ...state,
        [outcomeId]: { ...outcome, ...attributes },
      };

    case REMOVE_OUTCOMES:
      // Cant use map(parseInt) directly: https://stackoverflow.com/questions/14528397/strange-behavior-for-map-parseint
      const outcomeIds = action.outcomeIds.map((id) => parseInt(id, 10));
      return Object.keys(state).map((el) => parseInt(el, 10)).reduce((acc, outcomeId) => {
        const outcome = state[outcomeId];

        // ignore outcome ids from action not existing in
        // state.outcomes
        if (!outcome) {
          return acc;
        }

        // If the outcome is not one of the ones we want to remove
        // we will add it to the acc without any change
        if (!outcomeIds.includes(outcomeId)) {
          acc[outcomeId] = outcome;
          return acc;
        }

        // If the outcome wants to be removed and it is the last ref count
        // we wont add it to the acc
        if (outcome.refCount === 1) {
          return acc;
        }

        // In any other case add the outcome to the acc with refCount decreased by 1
        acc[outcomeId] = {
          ...outcome,
          refCount: outcome.refCount - 1,
        };
        return acc;
      }, {});

    default:
      return state;
  }
};

/* eslint
no-case-declarations:"off",
no-shadow:"off",
*/
// no-shadow is disabled because of https://github.com/eslint/eslint/issues/4473
