import PropTypes from 'prop-types';
import { format as priceFormat } from 'bv-helpers/price';
import { t } from 'bv-i18n';

const MultiplePrice = ({ multiple, anySpSingle }) => (
  <div className="single-price option-column">
    <span className="odds-price">
      {multiple.decimalPrice !== 0 && !anySpSingle ? priceFormat(multiple.textFormattedPrice) : t('sp')}
    </span>
  </div>
);

MultiplePrice.propTypes = {
  multiple: PropTypes.isRequired,
  anySpSingle: PropTypes.bool.isRequired,
};

export default MultiplePrice;
