import { decimalPrice } from 'bv';
import { getStakeId, getStakeValue } from 'Betslip/helpers';
import { safeAdd, safeSub } from 'bv-helpers/number';

const notCalculableMultipleWinBetTypeIds = [109, 101, 115];

const getStake = (stakes, bet) => {
  const stakeId = getStakeId(bet);
  return stakes[stakeId] || { value: '0', ews: false };
};

const hasPrice = (bet, stake) => (
  bet.decimalPrice !== 0 && bet.decimalPrice !== 0 && (!stake || stake.sp !== true)
);

export const isSomeSingleNonCalculable = (singles, stakes) => (
  singles.some((single) => {
    if (single.decimalPrice === 0) {
      return true;
    }
    const stake = getStake(stakes, single);
    if (stake && stake.sp === true) {
      return true;
    }
    return false;
  })
);

export const isSomeMultipleWithStake = (multiples, stakes) => (
  multiples.some((multiple) => {
    const stake = getStake(stakes, multiple);
    const stakeValue = getStakeValue(stake);
    return hasPrice(multiple, stake) && stakeValue > 0;
  })
);

const getPrice = (bet, ews, usePlacedOdds) => {
  if (usePlacedOdds) {
    return bet.oddsEW - bet.decimalPrice;
  }
  return ews ? bet.oddsEW : bet.decimalPrice;
};

const getStakeMultiplier = (ews, usePlacedOdds) => {
  // When we don't return bonses, we need to decide
  // how many times we are subtracting it
  // For EW bets this is 2 because the returns we calculate
  // is for 2 bets
  //
  // For placed-returns calculations, this is only one bet,
  // this needs to be only 1, because placed value is for
  // one bet only
  if (usePlacedOdds) {
    return 1;
  }
  return ews ? 2 : 1;
};

export const singlePotentialReturns = ({
  single,
  stake,
  usePromocash,
  removeStakeForBonusBet,
  usePlacedOdds,
}) => {
  const stakeValue = getStakeValue(stake);
  if (stakeValue === 0) {
    return 0;
  }

  if (!hasPrice(single, stake)) {
    return 0;
  }

  return (
    (stakeValue * getPrice(single, stake.ews, usePlacedOdds))
    - (
      usePromocash && removeStakeForBonusBet
        ? stakeValue * getStakeMultiplier(stake.ews, usePlacedOdds)
        : 0
    )
  );
};

export const singleEnhancedReturns = ({
  single,
  enhancedOutcome,
  stake,
  usePromocash,
  removeStakeForBonusBet,
}) => {
  let stakeValue = getStakeValue(stake);
  if (stakeValue === 0) {
    return 0;
  }

  if (stakeValue < enhancedOutcome.minStake) {
    return 0;
  }

  if (!hasPrice(single, stake)) {
    return 0;
  }

  if (usePromocash) {
    return 0;
  }

  if (stake.ews) {
    return 0;
  }

  stakeValue = Math.min(enhancedOutcome.maxStake, stakeValue);

  const normalReturns = singlePotentialReturns({
    single,
    stake: { value: stakeValue.toString(), ews: stake.ews },
    usePromocash,
    removeStakeForBonusBet,
  });

  return Math.max(
    0,
    (stakeValue * decimalPrice(enhancedOutcome.fractionalEnhancedOdds))
    - normalReturns,
  );
};

export const multiplePotentialReturns = ({
  multiple, singles, stakes, usePromocash, removeStakeForBonusBet, usePlacedOdds,
}) => {
  const stake = getStake(stakes, multiple);
  const stakeValue = getStakeValue(stake);
  if (stakeValue === 0) {
    return 0;
  }

  if (isSomeSingleNonCalculable(singles, stakes)) {
    return 0;
  }

  if (notCalculableMultipleWinBetTypeIds.includes(multiple.winBetTypeId)) {
    return 0;
  }

  return (
    (stakeValue * getPrice(multiple, stake.ews, usePlacedOdds))
    - (
      usePromocash && removeStakeForBonusBet
        ? stakeValue * getStakeMultiplier(stake.ews, usePlacedOdds)
        : 0
    )
  );
};

export const totalNormalReturns = ({
  singles,
  multiples,
  stakes,
  usePromocash,
  removeStakeForBonusBet,
  usePlacedOdds,
  singlesForMultiplesCalculations,
}) => {
  // if there's a stake on any bet that has SP price
  // the potential returns is 0
  const spBetWithStake = [...singles, ...multiples].find((bet) => (
    getStakeValue(getStake(stakes, bet)) !== 0 && !hasPrice(bet, getStake(stakes, bet))
  ));
  if (spBetWithStake) return 0;

  // A single with selected SP price but no stake makes all multiples SP
  // If we have at least one multple with a stake, the whole result becomes uncalculable
  if (isSomeMultipleWithStake(multiples, stakes) && isSomeSingleNonCalculable(singles, stakes)) {
    return 0;
  }

  const totalSinglesReturns = singles.reduce((acc, single) => {
    const stake = getStake(stakes, single);
    return safeAdd(acc, singlePotentialReturns({
      single,
      stake,
      usePromocash,
      removeStakeForBonusBet,
      usePlacedOdds,
    }));
  }, 0);

  const totalMultiplesReturns = multiples.reduce((acc, multiple) => (
    safeAdd(acc, multiplePotentialReturns({
      multiple,
      singles: singlesForMultiplesCalculations && singlesForMultiplesCalculations.length > 0
        ? singlesForMultiplesCalculations : singles,
      stakes,
      usePromocash,
      removeStakeForBonusBet,
      usePlacedOdds,
    }))
  ), 0);

  return safeAdd(totalSinglesReturns, totalMultiplesReturns);
};

export const normalTaxDeduct = (
  {
    singles,
    multiples,
    stakes,
    usePromocash,
    removeStakeForBonusBet,
    usePlacedOdds,
    singlesForMultiplesCalculations,
  },
  taxDeductPrc,
) => {
  const totalSinglesDeduct = singles.reduce((acc, single) => {
    const stake = getStake(stakes, single);
    return safeAdd(acc, parseFloat((singlePotentialReturns({
      single,
      stake,
      usePromocash,
      removeStakeForBonusBet,
      usePlacedOdds,
    }) * (taxDeductPrc / 100)).toFixed(2)));
  }, 0);

  const totalMultiplesDeduct = multiples.reduce((acc, multiple) => (
    safeAdd(acc, parseFloat((multiplePotentialReturns({
      multiple,
      singles: singlesForMultiplesCalculations && singlesForMultiplesCalculations.length > 0
        ? singlesForMultiplesCalculations : singles,
      stakes,
      usePromocash,
      removeStakeForBonusBet,
      usePlacedOdds,
    }) * (taxDeductPrc / 100)).toFixed(2)))
  ), 0);

  return safeAdd(totalSinglesDeduct, totalMultiplesDeduct);
};

export const totalEnhancedReturns = ({
  singles,
  enhancedOutcomesById,
  stakes,
  usePromocash,
  removeStakeForBonusBet,
  usePlacedOdds,
}) => (
  singles.reduce((acc, single) => {
    const enhancedOutcome = enhancedOutcomesById[single.outcomeId];
    if (!enhancedOutcome) {
      return acc;
    }

    const stake = getStake(stakes, single);
    return safeAdd(acc, singleEnhancedReturns({
      single,
      enhancedOutcome,
      stake,
      usePromocash,
      removeStakeForBonusBet,
      usePlacedOdds,
    }));
  }, 0)
);

export const enhancedTaxDeduct = (
  {
    singles,
    enhancedOutcomesById,
    stakes,
    usePromocash,
    removeStakeForBonusBet,
    usePlacedOdds,
  },
  taxDeductPrc,
) => (
  singles.reduce((acc, single) => {
    const enhancedOutcome = enhancedOutcomesById[single.outcomeId];
    if (!enhancedOutcome) {
      return acc;
    }

    const stake = getStake(stakes, single);
    return safeAdd(acc, parseFloat((singleEnhancedReturns({
      single,
      enhancedOutcome,
      stake,
      usePromocash,
      removeStakeForBonusBet,
      usePlacedOdds,
    }) * (taxDeductPrc / 100)).toFixed(2)));
  }, 0)
);

export const netReturns = (grossReturns, taxDeductions) => safeSub(grossReturns, taxDeductions);

export const totalTaxDeduct = (returnOpts, taxDeductPrc) => (
  safeAdd(normalTaxDeduct(returnOpts, taxDeductPrc), enhancedTaxDeduct(returnOpts, taxDeductPrc))
);

export const totalPotentialReturns = (opts) => (
  safeAdd(totalNormalReturns(opts), totalEnhancedReturns(opts))
);

export const totalPlacedReturns = (opts) => (
  safeAdd(totalNormalReturns({ ...opts, usePlacedOdds: true }), totalEnhancedReturns(opts))
);

export const netTotalPotentialReturns = (returnOpts, taxDeductPrc) => {
  const grossReturns = totalPotentialReturns(returnOpts);
  if (grossReturns <= 0) return 0;

  const taxDeductions = totalTaxDeduct(returnOpts, taxDeductPrc);
  return netReturns(grossReturns, taxDeductions);
};

export const netTotalPlacedReturns = (returnOpts, taxDeductPrc) => (
  netTotalPotentialReturns({ ...returnOpts, usePlacedOdds: true }, taxDeductPrc)
);

export const netTotalEnhancedReturns = (returnOpts, taxDeductPrc) => {
  const grossReturns = totalEnhancedReturns(returnOpts);
  if (grossReturns <= 0) return 0;

  const taxDeductions = enhancedTaxDeduct(returnOpts, taxDeductPrc);
  return netReturns(grossReturns, taxDeductions);
};
