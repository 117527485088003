import { localStorage, isDesktop } from 'bv';
import { set as lsSet } from 'bv-local-storage';
import { showBetslip } from 'right-sidebar';
import {
  addOutcomes, removeOutcomes, updateOutcome, upsertOutcomes,
} from 'Sportsbook/outcomes/duck';
import { makeGetOutcome } from 'Sportsbook/outcomes/selector';
import { publish } from 'bv-services/internal-event-bus';
import outcomePresenter from 'Sportsbook/outcomes/presenter';
import {
  getBetslipSingles,
  getBetslipMultiples,
  getBetslipOutcomeIds,
  getStake,
  getStakes,
  getUsePromocash,
  getStatus,
  getAcceptAllPriceChangeToggleValue,
  getCurrentTab,
  getBetslipInited,
  getBetslipIsSubmitting,
  getBetslipSubmitId,
} from './selector';
import {
  addToBetslipInit,
  addToBetslipSuccess,
  multiAddToBetslipInit,
  removeFromBetslipInit,
  removeFromBetslipSuccess,
  resetBetslipSuccess,
  setBetDelay,
  betslipSubmitInit,
  betslipSubmitSuccess,
  updateBetslipPrices,
  setStakeValue,
  setStake,
  emptyBetslip,
  clearMultipleStakes,
  betslipSetTab,
  betslipReplaceSingle,
  betslipCancelDelay,
  BETSLIP_STATUS,
} from './duck';

import {
  submitBetslipRequest,
  delayedBetStatusRequest,
  addToBetslipRequest,
  removeFromBetslipRequest,
  fetchPrices,
  multipleAddToBetslipRequest,
  betslipFetch,
  replaceSingle,
} from './api';
import * as dataLayer from './data_layer';
import {
  delay,
  generatePriceDeltas,
  validatePriceChanges,
  updateLineDescription,
  extractLineValueFromDescription,
} from './helpers';
import sanitizeSingle from './sanitizers/single';
import sanitizeMultiple from './sanitizers/multiple';

const getOutcome = makeGetOutcome();
const getOutcomeIdsFromShowResponse = (resp) => resp.singles.map((single) => single.outcome_id);

// when an issue returns for an EW selection for multiples
// it references the each_way_win_bet_type_id in the error, while
// we store the stake info by win_bet_type_id, so to produce the correct
// stakeId we need to add that back into the issue.issue from the
// attached multiple it comes with
const getStakeId = ({ issue, multiple }) => {
  const betTypeId = multiple ? multiple.win_bet_type_id : null;
  return (issue.outcome ? `s-${issue.outcome}` : `m-${betTypeId}`);
};

export const refreshOutcomesFromShowResponse = (showResponse, dispatch) => {
  const outcomes = showResponse?.singles?.map((single) => ({
    id: single.outcome_id,
    oid: single.outcome_id,
    des: single.outcome_description,
    desc: single.outcome_description,
    h: single.outcome_hidden,
    hidden: single.outcome_hidden,
    os: single.outcome_status_id,
    ms: single.market_status_id,
    prid: single.price_id,
    prd: single.price,
    pr: single.price_text_formatted,
    mtid: single.market_type_id,
    pid: single.period.id,
    outcomeKey: single.outcome_key,
    outcomeLineDistance: single.outcome_line_distance,
    eventDescription: single.event_description,
    marketId: single.market_id,
    eventId: single.event_id,
    timestamp: single.outcome_written,
    lineFollowed: single.line_followed,
  }));
  if (outcomes) dispatch(upsertOutcomes(outcomes));
};

const addToBetslipResponseHandler = (response) => response;

export const addToBetslip = (
  outcomeId,
  opts,
  responseHandler = addToBetslipResponseHandler,
) => (dispatch, getState) => {
  const state = getState();
  const outcome = state.outcomes[outcomeId];
  if (!outcome) return null;
  if (getBetslipIsSubmitting(state)) return null;
  dispatch(addToBetslipInit(outcomeId, opts));

  const market = Object.values(state.markets || {}).find((m) => m.o.includes(outcomeId)) || {};

  dataLayer.pushAddOutcomeEvent(outcomeId);
  dispatch(addOutcomes([{ id: outcomeId }]));

  return addToBetslipRequest({
    outcome: outcomePresenter(outcome),
    market,
    opts,
  }).then((resp) => {
    dispatch(addToBetslipSuccess(getOutcomeIdsFromShowResponse(resp), responseHandler(resp)));
    refreshOutcomesFromShowResponse(resp, dispatch);
    if (isDesktop()) showBetslip();
  });
};

export const addMultipleToBetslip = (outcomeIds) => (dispatch, getState) => (
  new Promise((resolve, reject) => {
    if (getBetslipIsSubmitting(getState())) {
      reject();
      return;
    }

    dispatch(addOutcomes(outcomeIds.map((id) => ({ id }))));
    dispatch(multiAddToBetslipInit(outcomeIds));

    multipleAddToBetslipRequest({ outcomeIds }).then((resp) => {
      dispatch(addToBetslipSuccess(getOutcomeIdsFromShowResponse(resp), resp));
      refreshOutcomesFromShowResponse(resp, dispatch);
      if (isDesktop()) showBetslip();
      resolve();
    }).catch(() => {
      reject();
    });
  })
);

export const removeFromBetslip = (outcomeId) => (dispatch, getState) => {
  if (getBetslipIsSubmitting(getState())) return Promise.resolve();

  dispatch(removeFromBetslipInit(outcomeId));
  return removeFromBetslipRequest(outcomeId).then((resp) => {
    // Rapid fire remove requests and buggy sessions can result
    // in an outcome delete request to be sent and the result still
    // containing that outcome we tried to delete.
    // in this case lowering the ref number could lead to bugs, so check the
    // payload if the outcome was, in-fact removed
    const state = getState();
    const newOutcomeIds = getOutcomeIdsFromShowResponse(resp);
    const oldOutcomeIds = getBetslipOutcomeIds(state);
    const removedOutcomeIds = oldOutcomeIds.filter((o) => !newOutcomeIds.includes(o));
    if (removedOutcomeIds.length > 0) {
      dispatch(removeOutcomes(removedOutcomeIds));
      removedOutcomeIds.forEach((oId) => {
        dataLayer.pushRemoveOutcomeEvent(oId, state);
      });
    }

    dispatch(removeFromBetslipSuccess(newOutcomeIds, resp));
    refreshOutcomesFromShowResponse(resp, dispatch);

    // clear stakes for multiples, we can't be sure if they are the same bet
    // as before the addition
    dispatch(clearMultipleStakes());
  });
};

export const resetBetslip = (force = false) => (dispatch, getState) => {
  const inited = getBetslipInited(getState());
  if (inited && !force) {
    return Promise.resolve();
  }

  dispatch(removeOutcomes(getBetslipOutcomeIds(getState()) || []));
  return betslipFetch().then((resp) => {
    const newOutcomeIds = getOutcomeIdsFromShowResponse(resp);
    dispatch(addOutcomes(newOutcomeIds.map((id) => ({ id }))));
    dispatch(resetBetslipSuccess(newOutcomeIds, resp));
    refreshOutcomesFromShowResponse(resp, dispatch);
    // clear stakes for multiples, we can't be sure if they are the same bet
    // as before the deletion
    dispatch(clearMultipleStakes());
  });
};

export const makeIssueHandler = (dispatch) => (issue) => {
  const stakeId = getStakeId(issue);
  switch (issue.issue.type) {
    case 'price_change':
      // multiple price changes are not handled here,
      // only way a price of a multiple could change if it's singles' change
      // triggering an outcome update when we have multiples on the betslip
      // will lead to a price-fetch and they will be updated from there
      if (issue.issue.bet_type === 'single') {
        dispatch(updateOutcome(issue.issue.outcome, {
          prid: issue.issue.new_price_id,
          prd: issue.issue.new_price,
          pr: issue.issue.new_price_text_formatted,
        }));
      }
      break;

    case 'reoffered_stake':
      dispatch(setStakeValue(stakeId, issue.issue.stake || 0));
      break;

    case 'suspended':
      dispatch(updateOutcome(issue.issue.outcome, {
        os: 2,
      }));
      break;

    case 'bet_attendant_rejected':
      dispatch(setStakeValue(stakeId, 0));
      break;

    default:
      break;
  }
};

export const submitBetslip = (
  betslipType, enhancedOutcomesById, filters,
) => (dispatch, getState) => {
  const submitId = (new Date()).getTime();
  dispatch(betslipSubmitInit({ submitId, acceptPriceChanges: true, acceptLineChanges: true }));

  const state = getState();
  const { showResponse } = state.betslip;
  const stakes = getStakes(state);
  const usePromocash = getUsePromocash(state);
  const outcomeIds = getBetslipOutcomeIds(state);
  const { outcomes } = state;

  const submitParams = {
    ...JSON.parse(JSON.stringify({
      showResponse,
      enhancedOutcomesById,
      outcomeIds,
      stakes,
      usePromocash,
      betslipType,
      outcomes,
    })),
    ...filters,
  };

  const issueHandler = makeIssueHandler(dispatch);

  const validateSubmitId = () => {
    if (getBetslipSubmitId(getState()) !== submitId) {
      throw new Error('submitId is different');
    }
  };

  const validateAfterDelayStatus = () => {
    if (getStatus(getState()) !== BETSLIP_STATUS.DELAY) {
      throw new Error('no longer in delay');
    }
  };

  const validateBetSubmissionStatus = (timeout) => {
    if (timeout === 'BET_ALREADY_BEING_SUBMITTED') {
      dispatch(betslipCancelDelay());
      throw new Error('bet already being processed');
    }
  };

  const handleBetDelay = (response) => {
    const { timeout, timeout_key: timeoutKey } = response;

    validateBetSubmissionStatus(timeout);
    validateSubmitId();

    if (timeout > 0) {
      dispatch(setBetDelay(response));

      return delay(timeout).then(() => {
        validateAfterDelayStatus();
        validateSubmitId();

        return delayedBetStatusRequest({
          ...submitParams,
          timeoutKey,
        });
      }).then(handleBetDelay);
    }

    return timeout ? { ...response.response, timeout } : response;
  };

  return submitBetslipRequest(submitParams)
    .then(handleBetDelay)
    .then((submitResponse) => {
      dispatch(betslipSubmitInit({
        submitId,
        acceptPriceChanges: false,
        acceptLineChanges: false,
      }));

      if (submitResponse.success || submitResponse.timeout === 'NOT_NEEDED') {
        Object.keys(stakes).forEach((key) => localStorage.remove(`stakes-v2-${key}`));
        submitResponse?.singles?.forEach((single) => {
          const outcome = submitResponse.outcomes.byId[single.outcomeId];
          if (outcome.priceChanged) {
            dispatch(updateOutcome(single.outcomeId, {
              prd: outcome.decimalPrice,
              pr: outcome.priceFormatted,
            }));
          }
        });
        lsSet('usePromocash', false);
        dataLayer.pushBetSubmitEvent(submitResponse, betslipType === 'mini-betslip' ? 'quick_bet' : 'betslip');
        return dispatch(betslipSubmitSuccess(submitResponse));
      }
      switch (submitResponse.type) {
        case 'review': {
          // set betslip status back to READY, finish the submission state
          // take per bet-item problems, run the handlers per issue and save the
          // issues indexed by stake-id for the UI to display them later and throw
          // away the submit-response
          const submitResponsePromise = dispatch(betslipSubmitSuccess(null, Object.fromEntries(
            submitResponse.issues.map((issue) => [getStakeId(issue), issue.issue]),
          )));
          submitResponse.issues.forEach(issueHandler);
          return submitResponsePromise;
        }
        // just store the result and frontend
        // will render the correct dialog for the type on the UI
        default:
          return dispatch(betslipSubmitSuccess(submitResponse));
      }
    })
    .catch(() => null);
};

export const updatePrices = (updatedOutcome) => (dispatch, getState) => {
  const state = getState();
  const status = getStatus(state);
  // during submission, we need to ignore all price-changes,
  // we'll run a `send get/outcomes` on submit-success to catch-up with the prices
  if (status === BETSLIP_STATUS.SUBMIT) return null;

  const singles = getBetslipSingles(state);
  const multiples = getBetslipMultiples(state);
  const acceptPriceChangesPct = getAcceptAllPriceChangeToggleValue(state);

  const updatedSingle = singles.find((single) => single.outcomeId === updatedOutcome.id);
  if (!updatedSingle) return null;

  const shouldFetchPrice = updatedSingle.hasEW || multiples.length > 0;
  const shouldUpdatePrice = (
    updatedSingle && updatedSingle.decimalPrice && updatedSingle.decimalPrice === updatedOutcome.prd
  );

  if (shouldFetchPrice) {
    // when asking for new prices for ew / multiples,
    // need to use the new price on the updated single
    const updatedSingles = singles.map(
      (single) => (single.outcomeId === updatedSingle.outcomeId
        ? { ...single, decimalPrice: updatedOutcome.prd }
        : single
      ),
    );
    return fetchPrices(updatedSingles, multiples, updatedOutcome, shouldUpdatePrice)
      .then((prices) => validatePriceChanges(updatedSingles, prices))
      .then((prices) => {
        dispatch(updateBetslipPrices(
          ...generatePriceDeltas(updatedOutcome, singles, multiples, prices), acceptPriceChangesPct,
        ));
      })
      .catch(() => null, // if validation fails we ignore the response
      );
  }

  // update the one single with the price from the original push message,
  // no need to talk to the backend
  dispatch(updateBetslipPrices({
    [updatedSingle.outcomeId]: {
      priceId: updatedOutcome.prid,
      decimalPrice: updatedOutcome.prd,
      textFormattedPrice: updatedOutcome.pr,
    },
  }, {}, acceptPriceChangesPct));
  return null;
};

export const clearBetslip = () => (dispatch) => {
  lsSet('usePromocash', false);
  return dispatch(emptyBetslip({ clearSubmitResponse: true }));
};

export const updateSelectedBetslipTab = (
  currentTabSelectedManually, showResponse,
) => (dispatch, getState) => {
  const state = getState();
  const currentTab = getCurrentTab(state);
  const tabStates = {
    singles: showResponse && showResponse.singles > 0,
    acca: showResponse && showResponse.multiples.filter((m) => m.multiplicity === 1).length > 0,
    multiples: showResponse && showResponse.multiples.filter((m) => m.multiplicity > 1).length > 0,
  };

  let newTab = '';

  if (currentTabSelectedManually) {
    if (tabStates[currentTab]) {
      newTab = currentTab;
    } else if (currentTab === 'multiples' && tabStates.acca) {
      // if you were on multiples tab (can only get there by manual selection)
      // and now it's unavailable (removed an outcome) the fallback should be the
      // acca tab instead of singles
      newTab = 'acca';
    } else {
      newTab = 'singles';
    }
  } else if (!currentTabSelectedManually) {
    if (tabStates.acca) {
      // give preference to acca tab if it's available and the tabs have not been
      // interacted with before
      newTab = 'acca';
    } else {
      newTab = 'singles';
    }
  }

  dispatch(betslipSetTab(newTab, false));
};

export const followLineChange = ({
  getState, dispatch, outcomeId, outcomeKey, lineChangePush,
}) => {
  const state = getState();
  const singles = getBetslipSingles(state);
  const oldSingle = singles.find((s) => s.outcomeId === outcomeId);
  const oldMultiples = getBetslipMultiples(state);
  const oldOutcome = getOutcome(state, { id: outcomeId });
  const outcomeDelta = lineChangePush.find((o) => o.outcomeKey === outcomeKey);
  const oldStakeId = `s-${outcomeId}`;
  const oldStake = getStake(state, oldStakeId);

  // ignore push if
  // - new outcome can't be found in pushed delta by outcomeKeys
  // - old single is not in reduxState under betslip
  // - old outcome is not in reduxState under outcomes
  // - betslip is submitting or on bet-delay
  // - if old and new outcome is the same
  if (
    !outcomeDelta
    || !oldSingle
    || !oldOutcome
    || getBetslipIsSubmitting(state)
    || oldOutcome.id === outcomeDelta.outcomeId
  ) {
    return null;
  }

  const extra1 = outcomeDelta.extraKey1;
  const newLine = extractLineValueFromDescription(outcomeDelta.outcomeDescription, extra1);
  const newOutcomeDescription = updateLineDescription(
    oldSingle.outcomeDescription, outcomeDelta.outcomeDescription, extra1,
  );
  const newMarketDescription = updateLineDescription(
    oldSingle.marketTypeDescription, outcomeDelta.marketDescription, extra1,
  );
  const timestamp = Number((outcomeDelta.created * 1000).toFixed());
  const newOutcome = {
    ...oldOutcome,
    // in-play overview sets and uses this
    sDesc: newLine,

    // event-level uses these 2
    shD: newLine,
    extra1,

    oid: outcomeDelta.outcomeId,
    id: outcomeDelta.outcomeId,
    des: newOutcomeDescription,
    desc: newOutcomeDescription,
    legDescriptions: [
      newOutcomeDescription,
    ],
    market_id: outcomeDelta.marketId,
    mId: outcomeDelta.marketId,
    mid: outcomeDelta.marketId,
    h: outcomeDelta.hidden,
    hidden: outcomeDelta.hidden,
    os: outcomeDelta.outcomeStatus,
    ms: outcomeDelta.marketStatus,
    prid: outcomeDelta.priceId,
    prd: outcomeDelta.decimalPrice,
    pr: outcomeDelta.fractionalPrice,
    outcomeLineDistance: outcomeDelta.mblDistance,
    timestamp,
  };

  const newSingle = {
    ...oldSingle,
    outcomeId: outcomeDelta.outcomeId,
    outcomeDescription: newOutcomeDescription,
    marketTypeDescription: newMarketDescription,
    textFormattedPrice: outcomeDelta.fractionalPrice,
    decimalPrice: outcomeDelta.decimalPrice,
    outcomeLineDistance: outcomeDelta.mblDistance,
    priceId: outcomeDelta.priceId,
    marketId: outcomeDelta.marketId,
    lineFollowed: true,
    timestamp,
  };

  const newStakeId = `s-${outcomeDelta.outcomeId}`;
  dispatch(setStake(newStakeId, oldStake));

  dispatch(addOutcomes([newOutcome]));
  dispatch(removeOutcomes([outcomeId]));
  dataLayer.pushRemoveOutcomeEvent(outcomeId, state);
  dispatch(betslipReplaceSingle(outcomeId, newSingle, oldMultiples));

  publish('refreshEventLevel', { timestamp });

  // update betslip in rails session, refresh the betslip data from full fetch
  return replaceSingle(outcomeId, newOutcome.id).then((resp) => {
    const respSingle = resp?.singles.find((s) => s.outcome_id === newOutcome.id);
    // re-replace single in betslip to make price and all other fields consistent across
    // outcomes and betslip parts of the state
    if (respSingle) {
      dispatch(betslipReplaceSingle(
        respSingle.outcome_id,
        sanitizeSingle(respSingle),
        resp?.multiples?.map(sanitizeMultiple) || [],
      ));
    }

    refreshOutcomesFromShowResponse(resp, dispatch);
  });
};
