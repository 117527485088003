import formatPrice from 'Sportsbook/helpers/format_price';

// We could define opts as second parameter to this function initialized
// with a default {} value
export default (outcome) => ({
  ...outcome,
  prid: outcome.prid || outcome.priceId || outcome.cpid,
  formattedPrice: formatPrice(outcome.pr || outcome.priceCustomPriceFormat),
  formattedWasPrice: outcome.formattedWasPrice ? formatPrice(outcome.formattedWasPrice) : undefined,
});
