import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { format as priceFormat } from 'bv-helpers/price';

const spFallback = (price) => (price ? priceFormat(price) : t('javascript.na'));

const PriceRow = ({ selection, fractionalEnhancedOdds }) => (
  <div className="description_row">
    <span>{t('betslip.receipt.odds')}</span>
    <span>
      {
        fractionalEnhancedOdds ? (
          <>
            <span className="enhanced-price">
              <span className="bvs-event-icon is-bet-boost" />
              {priceFormat(fractionalEnhancedOdds)}
            </span>
            <span className={classnames('old-price', { 'price-changed': selection.priceChanged })}>
              {spFallback(selection.formattedPrice)}
            </span>
          </>
        ) : (
          <span className={classnames({ 'odds-changed': selection.priceChanged })}>
            {spFallback(selection.formattedPrice)}
          </span>
        )
      }
      { selection.priceChanged && (
        <span className="requested-price">
          {' '}
          (
          {
            selection.formattedRequestedPrice
              ? t('betslip.receipt.requested_price', {
                requested: priceFormat(selection.formattedRequestedPrice, {
                  decimalPlaces: 2,
                }),
              })
              : t('javascript.na')
          }
          )
        </span>
      )}
    </span>
  </div>
);

PriceRow.propTypes = {
  selection: PropTypes.isRequired,
  fractionalEnhancedOdds: PropTypes.isRequired,
};

export default PriceRow;
